<template>
  <div class="app-container">
    <el-container direction="vertical" class="box">
      <el-container direction="horizontal">
        <el-aside width="315px"> </el-aside>
        <el-container direction="vertical">
          <el-main class="el_main">
            <!-- 聊天窗口 -->
            <div class="chat_view" ref="chat_view" v-cloak>
              <div class="chat_out" v-for="(v, i) in now_list" :key="i">
                <!-- 系统自动发送的提示消息 -->
                <!-- <div class="tips" v-if="v.msg_type === 5">{{ v.msg_body }}</div> -->
                <!-- 左 -->
                <div class="chat_item left" v-if="v.target_client_type === 2">
                  <img class="chat_item_1" :src="v.from_user.avatar" alt="" />
                  <div class="chat_item_2">
                    <div class="timestamp">
                      {{ v.from_user.nickname }}&nbsp;&nbsp;&nbsp;{{
                        v.created_at
                      }}
                    </div>
                    <div class="content" v-if="v.msg_type === 1">
                      {{ v.msg_body }}
                    </div>
                    <div class="content_nobg" v-else-if="v.msg_type === 2">
                      <el-image
                        fit="contain"
                        :src="v.msg_body"
                        :lazy="false"
                        hide-on-click-modal
                      ></el-image>
                    </div>
                    <div class="content_nobg" v-else-if="v.msg_type === 3">
                      <video :src="v.msg_body" controls></video>
                    </div>
                    <div
                      class="file_box"
                      v-else-if="v.msg_type === 4"
                      v-down="JSON.parse(v.msg_body)"
                    >
                      <img src="@/assets/file_38.png" alt="" />
                      <div class="file_box_left">
                        <span class="file_box_left_1">{{
                          JSON.parse(v.msg_body).name
                        }}</span>
                        <span class="file_box_left_2">{{
                          JSON.parse(v.msg_body).size
                        }}</span>
                      </div>
                    </div>
                    <div
                      v-else-if="v.msg_type == 6"
                      class="voice_box"
                      @click="
                        playVoice(JSON.parse(v.msg_body), v.chat_record_id)
                      "
                    >
                      <div
                        class="spin"
                        :class="
                          playMsgid == v.chat_record_id ? 'voicePlay' : ''
                        "
                      ></div>
                      <div class="length">
                        {{ JSON.parse(v.msg_body).length }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 右 -->
                <div class="chat_item right" v-else>
                  <div class="chat_item_2">
                    <div class="timestamp">{{ v.time }}</div>
                    <div class="content" v-if="v.msg_type === 1">
                      {{ v.msg_body }}
                    </div>
                    <div class="content_nobg" v-else-if="v.msg_type === 2">
                      <el-image
                        fit="contain"
                        :src="v.msg_body"
                        :lazy="false"
                        hide-on-click-modal
                      ></el-image>
                    </div>
                    <div class="content_nobg" v-else-if="v.msg_type === 3">
                      <video :src="v.msg_body" controls></video>
                    </div>
                    <div
                      class="file_box"
                      v-else-if="v.msg_type === 4"
                      v-down="JSON.parse(v.msg_body)"
                    >
                      <div class="file_box_left">
                        <span class="file_box_left_1">{{
                          JSON.parse(v.msg_body).name
                        }}</span>
                        <span class="file_box_left_2">{{
                          JSON.parse(v.msg_body).size
                        }}</span>
                      </div>
                      <img src="@/assets/file_38.png" alt="" />
                    </div>
                    <div
                      v-else-if="v.msg_type == 6"
                      class="voice_box"
                      @click="
                        playVoice(JSON.parse(v.msg_body), v.chat_record_id)
                      "
                    >
                      <div class="length">
                        {{ JSON.parse(v.msg_body).length }}
                      </div>
                      <div
                        class="spin"
                        :class="
                          playMsgid == v.chat_record_id ? 'voicePlay' : ''
                        "
                      ></div>
                    </div>
                  </div>

                  <!-- <img class="chat_item_1" :src="v.from_user.avatar" alt="" /> -->
                </div>
              </div>
            </div>
          </el-main>
          <!-- 发送 -->
          <el-footer height="204px">
            <div class="footer_top">
              <upload
                :type="1"
                :limit="9"
                @update="update"
                style="margin-right: 10px"
              >
                <img src="@/assets/photo_25.png" alt="" />
              </upload>
              <!-- <upload :type="2" :limit="1" accept="*" @update="update">
                <img src="@/assets/file_25.png" alt="" />
              </upload> -->
            </div>
            <div class="input">
              <el-input
                ref="input_focus"
                resize="none"
                type="textarea"
                :rows="4"
                placeholder="在此输入..."
                v-model="msgText"
                @keydown.enter.native="keyDown"
              />
            </div>
            <div class="btn">
              <el-button
                type="primary"
                :disabled="!msgText"
                @click.native.prevent="sendBody"
                >发送</el-button
              >
            </div>
          </el-footer>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import request from "@/utils/request";
import upload from "@/components/upload.vue";
export default {
  components: { upload },
  data() {
    return {
      now_list: [],
      user_id: null,
      // pageLoading: false,
      //   窗口当前滚动所在位置
      now_scroll_top: 0,
      now_scroll_height: 0,
      msgText: "",
      msg_type: 1,
    };
  },
  computed: {
    // 滚动到底部
    listContainer() {
      return this.$refs["chat_view"];
    },
  },

  created() {
    this.user_id = this.$route.query.id;
    this.getCath();
    // let timer = setInterval(() => {
    //   this.getCath();
    // }, 1500);
    //清除定时器
    // this.$once("hook:beforeDestroy", () => {
    //   clearInterval(timer);
    // });
  },
  // beforeDestroy() {
  //   clearInterval(this.timer);
  // },
  mounted() {},
  methods: {
    getCath() {
      // this.pageLoading = true;
      request({
        url: "/api/backend/userSay/log",
        method: "get",
        params: { user_id: this.user_id },
      }).then((res) => {
        // this.pageLoading = false;
        // console.log(res.data);
        let data =res.data;
        console.log(data,'========data');
        console.log(data[0],'=======data.id');
        console.log(this.now_list,'========list');
        if (this.now_list.length > 0 && this.now_list[0].id != data[0].id) {
          this.$nextTick(() => {
            this.toBottom();
          });
        }
        this.now_list = res.data.reverse();
      });
    },
    // 滚动到底部
    toBottom() {
      //   let listContainer = this.$refs['chat_view']
      // listContainer.scrollTop = listContainer.scrollHeight
      this.listContainer.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    },
    // 发送图片视频文件等
    update(msg_body, msg_type) {
      this.msgText = msg_body;
      this.msg_type = msg_type;
      console.log(msgText, msg_type);
      this.sendBody();
    },
    // 点击发送按钮
    sendBody() {
      // this.form.msg_type = 1;
      if (!this.msgText) return;
      this.$refs.input_focus.focus(); //输入框一直保存输入状态
      // this.$throttle(this.sendMsg, 500);
      request({
        url: "/api/backend/userSay/say",
        method: "post",
        params: {
          user_id: this.user_id,
          msg_type: this.msg_type,
          msg_body: this.msgText,
        },
      }).then((res) => {
        // this.now_list = res.data.reverse();
        // this.$forceUpdate(); //强制更新
        this.msgText = "";
        this.getCath();
        this.$nextTick(() => {
          this.toBottom();
        });
        // this.pageLoading = false;
      });
    },
    // 播放语音
    playVoice(msg, id) {
      if (this.playMsgid) {
        this.AUDIO.pause();
        console.log("暂停了");
        this.playMsgid = null;
        return;
      }
      this.playMsgid = id;
      this.AUDIO.src = msg.voiceUrl;
      this.$nextTick(() => {
        this.AUDIO.play();
        console.log("播放了");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin flex($fd: row, $ai: stretch, $jc: flex-start, $fw: nowrap) {
  display: flex;
  flex-direction: $fd;
  align-items: $ai;
  justify-content: $jc;
  flex-wrap: $fw;
}
.el-container {
  width: 1139px;
  // height: 935px;
  background-color: #fff;
  // .el-header {
  //   width: 1139px;
  //   //   height: 69px;
  //   border-bottom: 2px solid #f8f8f8;
  //   @include flex(row, center, space-between);
  //   .box_nav_left {
  //     @include flex(row, center);
  //     img {
  //       width: 25px;
  //       height: 25px;
  //       margin-right: 5px;
  //     }
  //     span {
  //       font-size: 22px;
  //       height: 29px;
  //       line-height: 29px;
  //       font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  //       font-weight: bold;
  //       color: #333333;
  //     }
  //   }
  //   .box_nav_right {
  //     cursor: pointer;
  //     @include flex(row, center);
  //     img {
  //       width: 16px;
  //       height: 16px;
  //       margin-right: 5px;
  //     }
  //     span {
  //       height: 22px;
  //       line-height: 22px;
  //       font-size: 16px;
  //       font-family: PingFangSC-Regular, PingFang SC;
  //       font-weight: 400;
  //       color: #1875ff;
  //     }
  //   }
  // }
  .el-container {
    .el-aside {
      border-right: 1px solid #f8f8f8;
      &::-webkit-scrollbar {
        display: none;
      }
      .msg {
        .item {
          cursor: pointer;
          height: 75px;
          width: 313px;
          @include flex(row, center, space-between);
          .avatar {
            width: 45px;
            height: 45px;
            margin-right: 10px;
            margin-left: 15px;
            position: relative;
            .unread {
              width: 45px;
              height: 45px;
              .is-fixed {
                right: -3px;
                transform: translateY(-50%);
              }
              img {
                width: 45px;
                height: 45px;
                border-radius: 50%;
              }
            }
            //   img {
            //     width: 45px;
            //     height: 45px;
            //     border-radius: 50%;
            //   }
            //   div {
            //     position: absolute;
            //     right: 3px;
            //     top: 0;
            //     // width: 16px;
            //     height: 16px;
            //     line-height: 16px;
            //     padding: 1px 5px;
            //     background: #e63838;
            //     font-size: 10px;
            //     font-family: MicrosoftYaHei;
            //     color: #ffffff;
            //     border-radius: 50%;
            //   }
          }
          .item_text {
            flex: 1;
            padding: 15px 0 14px;
            padding-right: 15px;
            border-bottom: 1px solid #f8f8f8;
            .item_text_1 {
              @include flex(row, stretch, space-between);
              .name {
                height: 22px;
                line-height: 22px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
              }
              .time {
                height: 17px;
                line-height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
              }
            }
            .item_text_2 {
              height: 18px;
              line-height: 18px;
              width: 229px;
              text-align: left;
              margin-top: 5px;
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .highlight {
              color: #e63838;
            }
          }
        }
        .avtive {
          background: #1875ff;
          .item_text {
            border-bottom: none;
            .item_text_1 {
              .name {
                color: #ffffff;
              }
              .time {
                color: #ffffff;
              }
            }
            .item_text_2 {
              color: #ffffff;
            }
          }
        }
      }
    }
    .el-container {
      .el-header {
        padding: 0 26px;
        width: 100%;
        @include flex(row, center);
        .user_name {
          font-size: 22px;
          font-family: MicrosoftYaHei;
          color: #333333;
        }
      }
      .el-main {
        padding: 0;
        height: 583px;
        background: #f8f8f8;
        //   overflow-anchor: auto;
        //   &::-webkit-scrollbar {
        //     display: none;
        //   }
        .chat_view {
          padding: 21px 25px;

          .chat_out {
            margin-top: 12px;
            //   opacity: 0;
            //   transform: translateY(-100%);
            //   animation: hiddenToShow 0.35s ease-out 0s forwards;
            &:nth-child(1) {
              margin-top: 0;
            }
            .tips {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 600;
              color: #999999;
              margin-bottom: 5px;
            }
            .chat_item {
              .chat_item_1 {
                width: 42px;
                height: 42px;
                border-radius: 50%;
              }
              .chat_item_2 {
                .timestamp {
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #999999;
                  margin-bottom: 5px;
                }
                .content {
                  padding: 10px 15px;
                  max-width: 369px;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  // display: table;
                  display: inline-block;
                  white-space: pre-line;
                  // img {
                  //   width: 100%;
                  //   height: 100%;
                  // }
                  video {
                    //   width: 300px;
                    width: 100%;
                    height: 250px;
                  }
                  .el-image {
                    height: 200px;
                  }
                }
                .content_nobg {
                  max-width: 369px;
                  border-radius: 6px;
                  @include flex(row, center, center);
                  video {
                    //   width: 300px;
                    //   width: 100%;
                    background: rgba(0, 0, 0, 0.2);
                    width: 250px;
                    height: 250px;
                    border-radius: 6px;
                  }
                  .el-image {
                    background: rgba(0, 0, 0, 0.2);
                    height: 250px;
                    border-radius: 6px;
                  }
                }
                .file_box {
                  padding: 10px 15px;
                  max-width: 369px;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  // display: table;

                  @include flex(row, center);
                  .file_box_left {
                    @include flex(column);
                    .file_box_left_1 {
                      font-size: 14px;
                      font-weight: 400;
                      color: #ffffff;
                      line-height: 20px;
                    }
                    .file_box_left_2 {
                      font-size: 12px;
                      font-weight: 400;
                      color: #ffffff;
                    }
                  }
                  img {
                    width: 38px;
                    height: 38px;
                  }
                }
                .voice_box {
                  padding: 10px 15px;
                  max-width: 369px;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  @include flex(row, center, space-between);
                  cursor: pointer;
                  .spin {
                    width: 24px;
                    height: 20px;
                  }
                  // 播放录音动画
                  .voicePlay {
                    animation-name: voicePlay;
                    animation-duration: 1s;
                    animation-direction: normal;
                    animation-iteration-count: infinite;
                    animation-timing-function: steps(3);
                  }

                  @keyframes voicePlay {
                    0% {
                      background-position: 0;
                    }

                    100% {
                      background-position: 100%;
                    }
                  }
                }
              }
            }
            .left {
              @include flex(row, stretch, flex-start);
              .chat_item_1 {
                margin-right: 10px;
              }
              .chat_item_2 {
                text-align: left;
                .timestamp {
                  // text-align: left;
                }
                .content {
                  // background: #f8f8f8;
                  background: #fff;
                  border-radius: 4px 20px 20px 20px;
                  color: #333333;
                }
                .file_box {
                  background: #f8f8f8;
                  border-radius: 4px 20px 20px 20px;
                  color: #333333;
                  .file_box_left {
                    .file_box_left_1 {
                      color: #333333;
                    }
                    .file_box_left_2 {
                      color: #333333;
                      text-align: right;
                    }
                  }
                  img {
                    margin-right: 32px;
                  }
                }
                .voice_box {
                  background: #f8f8f8;
                  border-radius: 4px 20px 20px 20px;

                  .spin {
                    background: url("../../assets/play_1.png") right 0 no-repeat;
                    background-size: 340%;
                  }
                }
              }
            }
            .right {
              @include flex(row, stretch, flex-end);
              .chat_item_1 {
                margin-left: 10px;
              }
              .chat_item_2 {
                text-align: right;
                .timestamp {
                  //   text-align: right;
                }
                .content {
                  text-align: left;
                  background: #1875ff;
                  border-radius: 20px 4px 20px 20px;
                  color: #ffffff;
                }
                .file_box {
                  text-align: left;
                  background: #1875ff;
                  border-radius: 20px 4px 20px 20px;
                  color: #ffffff;
                  img {
                    margin-left: 32px;
                  }
                }
                .voice_box {
                  text-align: left;
                  background: #1875ff;
                  border-radius: 20px 4px 20px 20px;
                  .spin {
                    background: url("../../assets/play_2.png") right 0 no-repeat;
                    background-size: 340%;
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
          @keyframes hiddenToShow {
            0% {
              opacity: 0;
              transform: translateY(100%);
            }

            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
        // 在填充数据之前隐藏
        [v-cloak] {
          display: none;
        }
      }
      .el-footer {
        border-top: 1px solid #f8f8f8;
        padding: 0 25px 24px;
        .footer_top {
          padding-top: 12px;
          @include flex(row, center);
          img {
            width: 25px;
            height: 25px;
            // margin-right: 15px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .input {
          margin-top: 12px;
          .el-textarea {
            .el-textarea__inner {
              border: none;
              padding: 0;
              font-size: 14px;
            }
          }
          .is-disabled {
            .el-textarea__inner {
              background-color: transparent;
            }
          }
        }
        .btn {
          margin-top: 12px;
          @include flex(row, center, flex-end);
          .el-button {
            width: 97px;
            height: 35px;
            line-height: 35px;
            border-radius: 6px;
            padding: 0;
          }
        }
      }
    }
  }
}
</style>