<template>
  <el-upload v-loading="loading" :action="qiniu.actionPath" :data="qiniu.postData" class="upload-one-component" :show-file-list="false" :disabled="disabled"
    :before-upload="beforeUpload" :on-success="uploadSuccess" :on-error="uploadError" :limit="limit" :accept="accept">
    <slot />
  </el-upload>
</template>
<script>
import { getQiniuToken } from '@/api/common'

export default {
  name: 'upload',
  props: {
    //   1图片视频  2文件
    type: {
      type: Number,
      default: 1,
    },
    // 允许上传个数
    limit: {
      type: Number,
      default: 1,
    },
    accept: {
      type: String,
      default: 'image/*,video/*',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    event: 'update', //自定义时间名
  },
  data() {
    return {
      loading: false,
      qiniu: {
        actionPath: '',
        baseUrl: '',
        postData: {},
      },
    }
  },
  created() {
    // getQiniuToken().then((response) => {
    //   // token可多次使用
    //   this.qiniu.actionPath = response.data.uploadUrl
    //   this.qiniu.baseUrl = response.data.baseUrl
    //   if (!this.qiniu.postData.token) {
    //     this.qiniu.postData.token = response.data.token
    //   }
    // })
  },
  methods: {
    async getToken() {
      let res = await getQiniuToken()
      this.qiniu.actionPath = res.data.uploadUrl
      this.qiniu.baseUrl = res.data.baseUrl
      this.qiniu.postData.token = res.data.token
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        }
      )
    },
    async beforeUpload(file) {
      await this.getToken()
      // 上传前需生成的GUID唯一名
      //   this.qiniu.postData.key = this.guid() + '.' + file.type.split('/')[1]
      this.qiniu.postData.key =
        this.guid() +
        '.' +
        file.name.split('.')[file.name.split('.').length - 1]
      this.loading = true
    },
    uploadSuccess(res, file) {
      if (this.type === 2) {
        let obj = {
          url: this.qiniu.baseUrl + '/' + res.key,
          name: file.name,
          size:
            file.size > 1024 * 1024
              ? (file.size / 1024 / 1024).toFixed(1) + 'MB'
              : (file.size / 1024).toFixed(1) + 'KB',
        }
        this.$emit('update', JSON.stringify(obj), 4) // 4 文件
      } else {
        let url = this.qiniu.baseUrl + '/' + res.key
        if (file.raw.type.split('/')[0] == 'image') this.$emit('update', url, 2)
        // 2 图片
        else if (file.raw.type.split('/')[0] == 'video')
          this.$emit('update', url, 3) // 3 视频
      }
      this.loading = false
    },
    uploadError(res) {
      this.loading = false
      this.$message({
        type: 'error',
        message: `操作失败,${res}`,
      })
    },
  },
}
</script>
